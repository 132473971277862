import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowLeft, Calendar, Link as LinkIcon, Edit2, Plus, Trash2, ExternalLink } from 'lucide-react';
import MediaGallery from '../components/MediaGallery';
import EditableField from '../components/EditableField';
import HomeButton from '../components/HomeButton';
import IconSelect from '../components/IconSelect';
import { useProjects } from '../hooks/useProjects';
import { useAdmin } from '../contexts/AdminContext';

const ProjectDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { projects, loading, isSaving, updateProject, uploadMedia } = useProjects();
  const project = projects.find((p) => p.id === id);
  const { isAdmin } = useAdmin();
  const [links, setLinks] = useState<Array<{ label: string; url: string }>>([]);
  const [isEditingLinks, setIsEditingLinks] = useState(false);
  const [linkSectionTitle] = useState('Project Links');

  const handleStartEditing = useCallback(() => {
    setIsEditingLinks(true);
  }, []);

  useEffect(() => {
    if (project?.links) {
      setLinks(Object.entries(project.links).map(([label, url]) => ({ label, url })));
    }
  }, [project]);

  const handleSaveLinks = async () => {
    if (!project) return;
    const linksObject = links.reduce((acc, { label, url }) => {
      if (label && url) acc[label] = url;
      return acc;
    }, {} as Record<string, string>);
    
    await updateProject(project.id, { links: linksObject });
    setIsEditingLinks(false);
  };

  const handleMediaUpload = async (file: File, index?: number, mediaType: 'image' | 'video' = 'image') => {
    if (!project) return;
    
    if (mediaType === 'delete') {
      if (index !== undefined && project.media) {
        const newMedia = project.media.filter((_, i) => i !== index);
        await updateProject(project.id, { media: newMedia });
      }
      return;
    }
    
    try {
      const url = await uploadMedia(file, project.id);
      if (!url) {
        throw new Error('Failed to get URL from upload');
      }
      
      if (index === -1) {
        await updateProject(project.id, { image: url });
      } else if (index !== undefined && project.media) {
        const newMedia = [...project.media];
        newMedia[index] = { ...newMedia[index], url };
        await updateProject(project.id, { media: newMedia });
      } else {
        const newMedia = [...(project.media || []), { type: mediaType, url }];
        await updateProject(project.id, { media: newMedia });
      }
    } catch (error) {
      console.error('Failed to upload/update media:', error);
    }
  };

  if (loading || !project) {
    return (
      <div className="container mx-auto px-4 py-12">
        <div className="text-center text-gray-400">Loading project...</div>
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className="container mx-auto px-4 py-12"
    >
      <HomeButton />
      <button 
        onClick={() => navigate('/project')}
        className="flex items-center gap-2 text-gray-400 hover:text-blue-400 transition-colors mb-8 font-medium"
      >
        <ArrowLeft className="w-5 h-5" />
        Back to Projects
      </button>

      <div className="grid grid-cols-1 lg:grid-cols-12 gap-12">
        <div className="lg:col-span-7">
          <MediaGallery
            project={project}
            onMediaUpload={handleMediaUpload}
            isSaving={isSaving}
          />
        </div>

        <div className="lg:col-span-5">
          <div className="flex items-center gap-4 mb-6">
            <div className="flex items-center gap-2 text-gray-400">
              <Calendar className="w-4 h-4" />
              <EditableField
                value={project.year}
                onSave={(value) => updateProject(project.id, { year: value })}
                className="inline-block"
              />
            </div>
            <div className="flex items-center gap-2 text-gray-400">
              <IconSelect
                selectedIcon={project.icon_key || 'CpuIcon'}
                onIconChange={(newIcon) => updateProject(project.id, { icon_key: newIcon })}
              />
              <EditableField
                value={project.category}
                onSave={(value) => updateProject(project.id, { category: value })}
                className="inline-block"
              />
            </div>
          </div>

          <EditableField
            value={project.title}
            onSave={(value) => updateProject(project.id, { title: value })}
            className="text-4xl font-semibold mb-6"
          />
          
          <EditableField
            value={project.description}
            onSave={(value) => updateProject(project.id, { description: value })}
            className="text-gray-400 mb-8 text-lg"
          />

          <div className="space-y-4">
            <h2 className="text-2xl font-medium mb-4">Project Overview</h2>
            <EditableField
              value={project.overview || 'No overview available.'}
              onSave={(value) => updateProject(project.id, { overview: value })}
              className="text-gray-400 leading-relaxed"
              textArea
            />
          </div>
          
          <div className="space-y-4 mt-8">
            <h2 className="text-2xl font-medium mb-4">Key Work</h2>
            <EditableField
              value={project.key_work || 'No key work details available.'}
              onSave={(value) => updateProject(project.id, { key_work: value })}
              className="text-gray-400 leading-relaxed"
              textArea
            />
          </div>

          <div className="mt-8">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-2xl font-medium">{linkSectionTitle}</h2>
              {!isEditingLinks && isAdmin && (
                <button
                  onClick={handleStartEditing}
                  className="p-2 hover:text-blue-400 transition-colors"
                >
                  <Edit2 className="w-4 h-4" />
                </button>
              )}
            </div>
            {!isEditingLinks ? (
              <div className="space-y-2">
                {links.map(({ label, url }, index) => (
                  <a
                    key={index}
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-2 group"
                  >
                    <div className="flex items-center gap-2 text-gray-400 hover:text-blue-400 transition-colors">
                      <LinkIcon className="w-4 h-4" />
                      <span>{label}</span>
                      <ExternalLink className="w-4 h-4 opacity-0 group-hover:opacity-100" />
                    </div>
                  </a>
                ))}
              </div>
            ) : (
              <div className="space-y-4">
                {links.map(({ label, url }, index) => (
                  <div key={index} className="flex items-center gap-2">
                    <input
                      type="text"
                      value={label}
                      onChange={(e) => {
                        const newLinks = [...links];
                        newLinks[index] = { ...newLinks[index], label: e.target.value };
                        setLinks(newLinks);
                      }}
                      className="flex-1 bg-[#1A1A1B] text-gray-100 p-2 rounded-lg"
                      placeholder="Link Label"
                    />
                    <input
                      type="text"
                      value={url}
                      onChange={(e) => {
                        const newLinks = [...links];
                        newLinks[index] = { ...newLinks[index], url: e.target.value };
                        setLinks(newLinks);
                      }}
                      className="flex-1 bg-[#1A1A1B] text-gray-100 p-2 rounded-lg"
                      placeholder="URL"
                    />
                    <button
                      onClick={() => {
                        setLinks(links.filter((_, i) => i !== index));
                      }}
                      className="p-2 hover:text-red-400 transition-colors"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </div>
                ))}
                <button
                  onClick={() => setLinks([...links, { label: '', url: '' }])}
                  className="w-full py-2 text-sm text-gray-400 hover:text-blue-400 transition-colors"
                >
                  <Plus className="w-4 h-4 inline mr-2" />
                  Add Link
                </button>
                <div className="flex justify-end gap-2 mt-4">
                  <button
                    onClick={() => {
                      setIsEditingLinks(false);
                      setLinks(Object.entries(project.links || {}).map(([label, url]) => ({ label, url })));
                    }}
                    className="px-4 py-2 text-sm hover:text-red-400 transition-colors"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleSaveLinks}
                    className="px-4 py-2 text-sm bg-blue-500 hover:bg-blue-600 rounded-lg transition-colors"
                  >
                    Save Links
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default ProjectDetail;