import React from 'react';
import { motion } from 'framer-motion';
import ProjectGrid from '../components/ProjectGrid';

const Projects = () => {
  return (
    <motion.div
      className="relative z-10 min-h-screen bg-[#111111]"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
    >
      <ProjectGrid />
    </motion.div>
  );
};

export default Projects;