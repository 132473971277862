import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowUpRight, Brain, Cpu, Database, Network, Palette, Bot, Globe, Rocket, Zap, Hand, MapPin } from 'lucide-react';
import VideoPlayer from './VideoPlayer';

const categoryIcons = {
  'BrainIcon': Brain,
  'CpuIcon': Cpu,
  'DatabaseIcon': Database,
  'NetworkIcon': Network,
  'PaletteIcon': Palette,
  'BotIcon': Bot,
  'GlobeIcon': Globe,
  'RocketIcon': Rocket,
  'ZapIcon': Zap,
  'HandIcon': Hand,
  'MapPinIcon': MapPin,
} as const;

interface ProjectCardProps {
  id: string;
  title: string;
  description: string;
  image: string;
  year: string;
  category: string;
  icon_key?: string;
}

const ProjectCard: React.FC<ProjectCardProps> = ({
  id,
  title,
  description,
  image,
  year,
  category,
  icon_key = 'CpuIcon',
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const Icon = categoryIcons[icon_key as keyof typeof categoryIcons] || categoryIcons['CpuIcon'];

  return (
    <Link
      to={`/project/${id}`}
      className="block aspect-[4/3] relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <motion.div 
      variants={{
        hidden: { opacity: 0, y: 20 },
        visible: { 
          opacity: 1, 
          y: 0,
          transition: { duration: 0.5 }
        }
      }}
      className="group relative h-full overflow-hidden"
      >
      <div className="absolute inset-0 overflow-hidden bg-[#0A0A0B]">
        <div className="h-full group-hover:scale-105 group-hover:brightness-75 transition-all duration-700">
          {image.endsWith('.mp4') || image.endsWith('.webm') ? (
            <VideoPlayer
              src={image}
              isHovered={isHovered}
              className="w-full h-full object-cover"
            />
          ) : (
            <img
              src={image}
              alt={title}
              className="w-full h-full object-cover"
            />
          )}
        </div>
        <div className="absolute inset-0 bg-gradient-to-b from-black/0 via-black/50 to-black/90 opacity-0 group-hover:opacity-100 transition-all duration-500 ease-in-out" />
      </div>
      <div className="absolute inset-0 p-8 flex flex-col justify-end">
        <div className="flex items-center gap-4 text-sm tracking-wider mb-4">
          <span className="text-blue-400 opacity-0 group-hover:opacity-100 transition-opacity duration-500">{year}</span>
          <span className="w-[1px] h-4 bg-gray-500 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
          <span className="text-gray-200 flex items-center gap-2 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
            <Icon className="w-4 h-4" />
            {category}
          </span>
        </div>
        <div className="transform translate-y-8 group-hover:translate-y-0 opacity-0 group-hover:opacity-100 transition-all duration-500">
          <h3 className="text-2xl font-bold mb-3 text-white">
            {title.replace(/[\u{2700}-\u{27BF}]|[\u{1F300}-\u{1F9FF}]|[\u{2600}-\u{26FF}]/gu, '').trim()}
          </h3>
          <p className="text-gray-100 max-w-xl mb-4 text-sm leading-relaxed">{description}</p>
          <ArrowUpRight className="w-8 h-8 text-blue-400 transform -rotate-45 group-hover:rotate-0 transition-all duration-500 delay-150" />
        </div>
      </div>
      </motion.div>
    </Link>
  );
}

export default ProjectCard;