import React, { useRef, useEffect, useState } from 'react';

interface VideoPlayerProps {
  src: string;
  className?: string;
  isHovered?: boolean;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ src, className, isHovered }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [src]);

  useEffect(() => {
    if (videoRef.current && isLoaded) {
      if (isHovered) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
        videoRef.current.currentTime = 0;
      }
    }
  }, [isHovered, isLoaded]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting && videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
          }
        });
      },
      { threshold: 0.1 }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => observer.disconnect();
  }, []);

  const handleLoadedData = () => {
    setIsLoaded(true);
  };

  return (
    <video
      ref={videoRef}
      className={className}
      muted
      controls={false}
      playsInline
      loop
      preload="auto"
      onLoadedData={handleLoadedData}
    >
      <source src={src} type={src.endsWith('.mp4') ? 'video/mp4' : 'video/webm'} />
    </video>
  );
};

export default VideoPlayer;