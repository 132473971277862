import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Upload } from 'lucide-react';
import { useAdmin } from '../contexts/AdminContext';

interface ImageUploadProps {
  currentImage: string;
  onImageUpload: (file: File) => void;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ currentImage, onImageUpload }) => {
  const { isAdmin } = useAdmin();
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.webp'],
      'video/*': ['.mp4', '.webm']
    },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        onImageUpload(acceptedFiles[0]);
      }
    },
  });

  return (
    <div className="relative group">
      {currentImage.endsWith('.mp4') || currentImage.endsWith('.webm') ? (
        <video
          src={currentImage}
          className="w-full aspect-video object-cover rounded-lg"
          muted
          playsInline
          loop
          autoPlay
        />
      ) : (
        <>
          <img
            src={currentImage}
            alt="Project"
            className="w-full aspect-video object-cover rounded-lg"
          />
          {isAdmin && (
            <div
              {...getRootProps()}
              className="absolute inset-0 flex items-center justify-center bg-black/50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg cursor-pointer"
            >
              <input {...getInputProps()} />
              <div className="text-center">
                <Upload className="w-8 h-8 text-blue-400 mx-auto mb-2" />
                <p className="text-sm text-gray-200">
                  {isDragActive
                    ? 'Drop image here'
                    : 'Drag & drop or click to replace image'}
                </p>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ImageUpload;