import React, { useState } from 'react';
import { Edit2, Check, X } from 'lucide-react';
import { useAdmin } from '../contexts/AdminContext';

interface EditableFieldProps {
  value: string;
  onSave: (value: string) => void;
  className?: string;
  textArea?: boolean;
}

const EditableField: React.FC<EditableFieldProps> = ({ value, onSave, className = '', textArea }) => {
  const [isEditing, setIsEditing] = useState(false);
  const { isAdmin } = useAdmin();
  const [editValue, setEditValue] = useState(value);

  const handleSave = () => {
    onSave(editValue);
    setIsEditing(false);
  };

  const formatText = (text: string) => {
    return text.split('\n').map((line, i) => (
      <React.Fragment key={i}>
        {line.startsWith('-') ? (
          <div className="flex items-center gap-3 mb-2">
            <span className="text-blue-400 text-xl flex-shrink-0">•</span>
            <span>{line.substring(1).trim()}</span>
          </div>
        ) : (
          <div className="mb-2">{line}</div>
        )}
      </React.Fragment>
    ));
  };

  const handleCancel = () => {
    setEditValue(value);
    setIsEditing(false);
  };

  if (isEditing) {
    return (
      <div className="relative group">
        {textArea ? (
          <textarea
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
            className={`w-full bg-[#1A1A1B] text-gray-100 p-2 rounded-lg ${className}`}
            rows={4}
            autoFocus
          />
        ) : (
          <input
            type="text"
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
            className={`w-full bg-[#1A1A1B] text-gray-100 p-2 rounded-lg ${className}`}
            autoFocus
          />
        )}
        <div className="absolute top-2 right-2 flex gap-2">
          <button
            onClick={handleSave}
            className="p-1 hover:text-green-400 transition-colors"
          >
            <Check className="w-4 h-4" />
          </button>
          <button
            onClick={handleCancel}
            className="p-1 hover:text-red-400 transition-colors"
          >
            <X className="w-4 h-4" />
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="relative group">
      <div className={className}>
        {textArea ? formatText(value) : value}
      </div>
      {isAdmin && (
        <button
          onClick={() => setIsEditing(true)}
          className="absolute top-0 right-0 p-2 opacity-0 group-hover:opacity-100 hover:text-blue-400 transition-all"
        >
          <Edit2 className="w-4 h-4" />
        </button>
      )}
    </div>
  );
};

export default EditableField;