import React from 'react';
import { motion } from 'framer-motion';
import { Home } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const HomeButton = () => {
  const navigate = useNavigate();

  const handleHomeClick = () => {
    navigate('/', { replace: true, state: { skipParticles: false } });
    window.location.reload(); // Force reload to reinitialize particle scene
  };

  return (
    <motion.button
      onClick={handleHomeClick}
      className="fixed top-8 right-8 z-50 w-12 h-12 flex items-center justify-center bg-black/20 backdrop-blur-md border-[4px] border-blue-400/20 rounded-full text-blue-400/80 hover:text-blue-400 hover:border-blue-400/60 hover:scale-105 hover:shadow-[0_0_15px_rgba(96,165,250,0.3)] hover:rotate-12 transition-all duration-200 ease-out group"
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      whileHover={{ y: -2 }}
      whileTap={{ scale: 0.95 }}
    >
      <Home className="w-5 h-5 group-hover:animate-pulse group-hover:drop-shadow-[0_0_8px_rgba(96,165,250,0.5)]" />
    </motion.button>
  );
};

export default HomeButton;