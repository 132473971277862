import React, { useEffect } from 'react';
import { useState } from 'react';
import { Project } from '../data/projects';
import ImageUpload from './ImageUpload';
import { useProjects } from '../hooks/useProjects';
import { supabase } from '../lib/supabase';
import { useAdmin } from '../contexts/AdminContext';
import { Trash2 } from 'lucide-react';

interface MediaGalleryProps {
  project: Project;
  onMediaUpload: (file: File, index?: number, mediaType?: 'image' | 'video') => void;
  isSaving: boolean;
}

const MediaGallery: React.FC<MediaGalleryProps> = ({ project, onMediaUpload, isSaving }) => {
  const { updateProject } = useProjects();
  const { isAdmin } = useAdmin();
  const [isUploading, setIsUploading] = useState(false);

  const handleDeleteMedia = async (index: number) => {
    if (!project.media) return;
    try {
      setIsUploading(true);
      const newMedia = project.media.filter((_, i) => i !== index);
      
      // Get the URL of the media being deleted
      const mediaToDelete = project.media[index];
      
      // Extract filename from URL
      const fileName = mediaToDelete.url.split('/').pop();
      if (!fileName) {
        throw new Error('Invalid file URL');
      }
      
      // Delete file from storage
      const { error: storageError } = await supabase.storage
        .from('project-media')
        .remove([fileName]);

      if (storageError) {
        throw storageError;
      }

      // Update project with new media array
      await updateProject(project.id, {
        media: newMedia.length > 0 ? newMedia : null
      });
    } catch (err) {
      console.error('Failed to delete media:', err);
    } finally {
      setIsUploading(false);
    }
  };

  const handleMediaUpload = async (file: File, index?: number) => {
    setIsUploading(true);
    try {
      if (!file) {
        throw new Error('No file selected');
      }
      
      const mediaType = file.type.startsWith('video/') ? 'video' : 'image';
      console.log('Uploading media:', { mediaType, index });
      await onMediaUpload(file, index, mediaType);
    } catch (err) {
      console.error('Media upload failed:', err);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="grid grid-cols-1 gap-6">
      <div className="relative">
        {(isUploading || isSaving) ? (
          <div className="absolute inset-0 bg-black/50 flex items-center justify-center">
            <div className="text-blue-400 animate-pulse">
              <svg className="animate-spin h-8 w-8 text-blue-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              <span className="mt-2 block">{isUploading ? 'Uploading...' : 'Saving...'}</span>
            </div>
          </div>
        ) : (
          <ImageUpload
            currentImage={project.image}
            onImageUpload={(file) => handleMediaUpload(file, -1)}
          />
        )}
      </div>

      {project.media && (
        <>
          {project.media.map((item, index) => (
            <div key={`${project.id}-${item.url}-${index}`} className="relative">
              {item.type === 'video' ? (
                <>
                  <div className="w-full rounded-lg overflow-hidden bg-black">
                    <video
                      controls
                      autoPlay
                      muted
                      loop
                      src={item.url}
                      className="w-full object-contain"
                      playsInline
                      preload="metadata"
                      onError={(e) => console.error('Video error:', e.currentTarget.error)}
                    />
                  </div>
                  {isAdmin && (
                    <div className="absolute top-4 right-4">
                      <button
                        onClick={() => handleDeleteMedia(index)}
                        className="p-2 bg-black/50 rounded-full hover:bg-red-500/70 transition-colors"
                      >
                        <Trash2 className="w-4 h-4 text-white" />
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {isAdmin ? (
                    <ImageUpload
                      currentImage={item.url}
                      onImageUpload={(file) => handleMediaUpload(file, index)}
                    />
                  ) : (
                    <img
                      src={item.url}
                      alt={`Project media ${index + 1}`}
                      className="w-full rounded-lg"
                    />
                  )}
                  {isAdmin && (
                    <div className="absolute top-4 right-4">
                      <button
                        onClick={() => handleDeleteMedia(index)}
                        className="p-2 bg-black/50 rounded-full hover:bg-red-500/70 transition-colors"
                      >
                        <Trash2 className="w-4 h-4 text-white" />
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          ))}
        </>
      )}

      {isAdmin && (
        <button
          onClick={() => {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = 'image/*,video/mp4,video/webm,video/ogg';
            input.onchange = (e) => {
              const file = (e.target as HTMLInputElement).files?.[0];
              if (file) {
                const mediaType = file.type.startsWith('video/') ? 'video' : 'image';
                handleMediaUpload(file, undefined, mediaType);
              }
            };
            input.click();
          }}
          disabled={isUploading || isSaving}
          className={`w-full py-4 border-2 border-dashed ${
            isUploading || isSaving
              ? 'border-gray-700 text-gray-600 cursor-not-allowed'
              : 'border-gray-700 text-gray-400 hover:text-blue-400 hover:border-blue-400'
          } rounded-lg transition-colors`}
        >
          {isUploading || isSaving ? 'Processing...' : 'Add Media'}
        </button>
      )}
    </div>
  );
};

export default MediaGallery;