import { motion, AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import ParticleScene from '../components/ParticleScene';

const Home = () => {
  return (
    <ParticleScene />
  );
};

export default Home;