import React from 'react';
import { motion } from 'framer-motion';
import { Zap } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface EnterButtonProps {
  onClick: () => void;
}

const EnterButton: React.FC<EnterButtonProps> = ({ onClick }) => {

  return (
    <div className="fixed inset-0 pointer-events-none flex items-center z-10">
      <div className="w-full flex justify-center -translate-x-8">
      <motion.button
        onClick={onClick}
        className="pointer-events-auto px-8 py-3 bg-transparent border-2 border-blue-400/30 hover:border-blue-400 text-blue-400 rounded-full flex items-center gap-2 backdrop-blur-sm hover:scale-105 active:scale-95"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        ENTER <Zap className="w-4 h-4" />
      </motion.button>
    </div>
    </div>
  );
};

export default EnterButton;