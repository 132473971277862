import React, { createContext, useContext, useState, useEffect } from 'react';
import { ADMIN_PASSWORD } from '../lib/env';

interface AdminContextType {
  isAdmin: boolean;
  toggleAdmin: (password: string) => boolean;
}

const AdminContext = createContext<AdminContextType>({
  isAdmin: false,
  toggleAdmin: () => false,
});

const MAX_ATTEMPTS = 3;
const LOCKOUT_DURATION = 900000; // 15 minutes

export const AdminProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [attempts, setAttempts] = useState(0);
  const [lastAttemptTime, setLastAttemptTime] = useState(0);

  const toggleAdmin = (password: string): boolean => {
    const now = Date.now();
    
    // Check if we're in lockout period
    if (attempts >= MAX_ATTEMPTS && now - lastAttemptTime < LOCKOUT_DURATION) {
      const remainingTime = Math.ceil((LOCKOUT_DURATION - (now - lastAttemptTime)) / 1000);
      const minutes = Math.floor(remainingTime / 60);
      const seconds = remainingTime % 60;
      throw new Error(`Too many attempts. Please wait ${minutes} minutes and ${seconds} seconds.`);
    }

    // Reset attempts if we're past the lockout period
    if (now - lastAttemptTime > LOCKOUT_DURATION) {
      setAttempts(0);
    }

    // Check password
    if (password === ADMIN_PASSWORD) {
      setIsAdmin(true);
      setAttempts(0);
      return true;
    } else {
      setAttempts(prev => prev + 1);
      setLastAttemptTime(now);
      return false;
    }
  };

  return (
    <AdminContext.Provider value={{ isAdmin, toggleAdmin }}>
      {children}
    </AdminContext.Provider>
  );
};

export const useAdmin = () => useContext(AdminContext);