import React from 'react';
import ProjectCard from './ProjectCard';
import { motion } from 'framer-motion';
import { useProjects } from '../hooks/useProjects';
import HomeButton from './HomeButton';
import ScrollIndicator from './ScrollIndicator';

const ProjectGrid = () => {
  const { projects, loading, error } = useProjects();

  if (loading) return null;
  if (error) {
    return (
      <div className="grid place-items-center h-96">
        <div className="text-red-400">{error}</div>
      </div>
    );
  }

  return (
    <motion.div 
      className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3"
      initial="hidden"
      animate="visible"
      variants={{
        visible: {
          transition: {
            staggerChildren: 0,
            delayChildren: 0.1
          }
        }
      }}
    >
      <HomeButton />
      <ScrollIndicator />
      {projects.map((project) => (
        <ProjectCard key={project.id} {...project} />
      ))}
    </motion.div>
  );
}

export default ProjectGrid;