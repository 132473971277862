import { useState, useEffect } from 'react';
import { supabase } from '../lib/supabase';
import type { Project } from '../data/projects';
import { defaultProjects } from '../data/projects';

const CACHE_KEY = 'projects_cache';
const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 hours

export function useProjects() {
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    fetchProjects();
  }, []);

  async function fetchProjects() {
    // Check cache first
    const now = Date.now();
    const cached = localStorage.getItem(CACHE_KEY);
    
    if (cached) {
      const { data, timestamp } = JSON.parse(cached);
      if (now - timestamp < CACHE_DURATION) {
        console.log('Using cached projects data');
        setProjects(data);
        setLoading(false);
        // Store timestamp with data for cache validation
        localStorage.setItem(CACHE_KEY, JSON.stringify({ data, timestamp: now }));
        return;
      }
    }

    try {
      console.log('Fetching projects...');
      const { data, error } = await supabase
        .from('projects')
        .select('*')
        .order('year', { ascending: false })
        .order('updated_at', { ascending: false });

      if (error) throw error;
      
      const projectsData = data || defaultProjects;
      setProjects(projectsData);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to fetch projects');
      // Use default projects as fallback
      setProjects(defaultProjects);
    } finally {
      setLoading(false);
    }
  }

  async function updateProject(id: string, updates: Partial<Project>) {
    try {
      setIsSaving(true);
      // Ensure media is properly formatted
      const formattedUpdates = { ...updates };
      if ('media' in formattedUpdates && !formattedUpdates.media) {
        formattedUpdates.media = null;
      }
            
      const { error } = await supabase
        .from('projects')
        .update(formattedUpdates)
        .eq('id', id);

      if (error) {
        throw error;
      }
      
      // Update local state
      setProjects(prev => prev.map(p => 
        p.id === id ? { ...p, ...formattedUpdates } : p
      ));
      
      // Clear cache to force fresh data on next load
      localStorage.removeItem(CACHE_KEY);
      
      // Fetch fresh data
      await fetchProjects();
      
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to update project');
    } finally {
      setIsSaving(false);
    }
  }

  async function uploadMedia(file: File, projectId: string) {
    try {
      if (!file || file.size === 0) {
        return null;
      }
      
      const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB
      if (file.size > MAX_FILE_SIZE) {
        throw new Error('File size exceeds 50MB limit');
      }

      const fileExt = file.name.split('.').pop();
      if (!fileExt) {
        throw new Error('Invalid file: missing extension');
      }
      
      const allowedImageTypes = ['jpg', 'jpeg', 'png', 'webp'];
      const allowedVideoTypes = ['mp4', 'webm', 'ogg'];
      
      const ext = fileExt.toLowerCase();
      if (!allowedImageTypes.includes(ext) && !allowedVideoTypes.includes(ext)) {
        throw new Error('Unsupported file type. Please use jpg, png, webp for images or mp4, webm, ogg for videos.');
      }
      
      const isVideo = file.type.startsWith('video/');
      
      const fileName = `${projectId}_${Date.now()}.${fileExt}`;
      
      // First get the URL to ensure we can access it after upload
      const { data: urlData } = await supabase.storage
        .from('project-media')
        .getPublicUrl(fileName);

      if (!urlData?.publicUrl) {
        throw new Error('Failed to generate public URL');
      }

      // Then perform the upload
      const { error: uploadError, data } = await supabase.storage
        .from('project-media')
        .upload(fileName, file, {
          cacheControl: '31536000',
          upsert: true,
          contentType: isVideo ? 'video/mp4' : file.type,
        });

      if (uploadError) {
        console.error('Upload error:', uploadError);
        throw uploadError;
      }
      
      return urlData.publicUrl;
    } catch (err) {
      console.error('Upload media error:', err);
      const errorMessage = err instanceof Error ? err.message : 'Failed to upload media';
      setError(errorMessage);
      return null;
    }
  }

  return {
    projects,
    loading,
    error,
    isSaving,
    updateProject,
    uploadMedia,
  };
}