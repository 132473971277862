import React, { useState, useEffect, useRef } from 'react';
import * as THREE from 'three';
import { useNavigate } from 'react-router-dom';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import GUI from 'lil-gui';
import { Lock } from 'lucide-react';
import { useAdmin } from '../contexts/AdminContext';
import AboutText from './AboutText';
import Header from './Header';
import EnterButton from './EnterButton';

const ParticleScene: React.FC = () => {
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  const cameraRef = useRef<THREE.PerspectiveCamera | null>(null);
  const controlsRef = useRef<OrbitControls | null>(null);
  const isAnimatingRef = useRef(false);
  const fadeOverlayRef = useRef<HTMLDivElement>(null);
  const targetCameraRotation = useRef(new THREE.Vector2(0, 0));
  const currentCameraPosition = useRef(new THREE.Vector3());
  const initialCameraPosition = useRef(new THREE.Vector3());
  const startCameraAnimationRef = useRef<(() => void) | null>(null);
  const lerpFactor = 0.03;
  const [isFading, setIsFading] = useState(true);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [password, setPassword] = useState('');
  const { toggleAdmin } = useAdmin();
  const [error, setError] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const touchStartRef = useRef<{ x: number; y: number } | null>(null);
  const rendererRef = useRef<THREE.WebGLRenderer | null>(null);
  const sceneRef = useRef<THREE.Scene | null>(null);
  const geometryRef = useRef<THREE.BufferGeometry | null>(null);
  const materialRef = useRef<THREE.ShaderMaterial | null>(null);

  const handlePasswordSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const success = toggleAdmin(password);
      if (success) {
        setShowPasswordModal(false);
        setPassword('');
        setError('');
      } else {
        setError('Invalid password');
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    }
  };

  const requestAnimationFrameId = useRef<number>();
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Initial fade in
    if (fadeOverlayRef.current) {
      fadeOverlayRef.current.style.opacity = '1';
      setTimeout(() => {
        fadeOverlayRef.current!.style.transition = 'opacity 2500ms ease-out';
        fadeOverlayRef.current!.style.opacity = '0';
        setIsFading(false);
      }, 500);
    }

    // Physics parameters with defaults
    const params = {
      attractorMassExponent: 6,
      particleGlobalMassExponent: 4,
      mouseRepulsionStrength: 5.7,
      mouseRepulsionRadius: 0.8,
      particleCount: 50000,
      fps: 0,
      maxSpeed: 2.26,
      velocityDamping: 0.069,
      spinningStrength: 7.05,
      colorTransitionThreshold: 0.07,
      scale: 0.18,
      fadeOutDuration: 300,
      boundHalfExtent: 14.1,
      backgroundColor: '#111111',
      controlsMode: 'translate',
      helperVisible: false,
      colorA: '#69c5ec',
      colorB: '#294ddb',
      reset: () => {
        // Recreate geometry with new particle count
        const positions = new Float32Array(params.particleCount * 3);
        const velocities = new Float32Array(params.particleCount * 3);
        
        for (let i = 0; i < params.particleCount * 3; i += 3) {
          positions[i] = (Math.random() - 0.5) * 5;
          positions[i + 1] = (Math.random() - 0.5) * 5;
          positions[i + 2] = (Math.random() - 0.5) * 5;
          velocities[i] = 0;
          velocities[i + 1] = 0;
          velocities[i + 2] = 0;
        }
        
        geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
        geometry.setAttribute('velocity', new THREE.BufferAttribute(velocities, 3));
        geometry.setAttribute('speed', new THREE.BufferAttribute(new Float32Array(params.particleCount), 1));
        geometry.attributes.position.needsUpdate = true;
        geometry.attributes.velocity.needsUpdate = true;
        geometry.attributes.speed.needsUpdate = true;
        
        points.geometry = geometry;
      }
    };

    if (!containerRef.current) return;

    // Scene setup
    const scene = new THREE.Scene();
    sceneRef.current = scene;
    const camera = new THREE.PerspectiveCamera(25, window.innerWidth / window.innerHeight, 0.1, 100);
    camera.position.set(6, 8, 12);
    const cameraTarget = new THREE.Vector3(0, 0, 0);
    cameraRef.current = camera;
    initialCameraPosition.current.copy(camera.position);
    currentCameraPosition.current.copy(camera.position);
    camera.lookAt(cameraTarget);

    const renderer = new THREE.WebGLRenderer({
      antialias: true,
      powerPreference: 'high-performance'
    });
    rendererRef.current = renderer;
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(params.backgroundColor);
    containerRef.current.appendChild(renderer.domElement);

    // Controls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controlsRef.current = controls;
    controls.enabled = false;

    // Lighting
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1.5);
    directionalLight.position.set(4, 2, 0);
    scene.add(directionalLight);

    // Attractors
    const attractors = [
      { 
        position: new THREE.Vector3(-0.5713, 0, -0.0048), 
        rotation: new THREE.Euler(0, 0, 2.4026),
        mass: 1e7 
      },
      { 
        position: new THREE.Vector3(-0.2485, -0.7272, -0.4684), 
        rotation: new THREE.Euler(0, 0, 1.5593),
        mass: 1e7 
      },
    ];

    // Attractor Helpers
    const helpersRingGeometry = new THREE.RingGeometry(1, 1.02, 32, 1, 0, Math.PI * 1.5);
    const helpersArrowGeometry = new THREE.ConeGeometry(0.1, 0.4, 12, 1, false);
    const helpersMaterial = new THREE.MeshBasicMaterial({ side: THREE.DoubleSide });

    attractors.forEach(attractor => {
      const helper = new THREE.Group();
      helper.scale.setScalar(0.325);
      helper.visible = false;
      
      const reference = new THREE.Object3D();
      reference.position.copy(attractor.position);
      reference.rotation.copy(attractor.rotation);
      reference.visible = false;
      scene.add(reference);
      
      const ring = new THREE.Mesh(helpersRingGeometry, helpersMaterial);
      ring.rotation.x = -Math.PI * 0.5;
      helper.add(ring);
      
      const arrow = new THREE.Mesh(helpersArrowGeometry, helpersMaterial);
      arrow.position.x = 1;
      arrow.position.z = 0.2;
      arrow.rotation.x = Math.PI * 0.5;
      helper.add(arrow);
      
      reference.add(helper);
      
      const transformControls = new TransformControls(camera, renderer.domElement);
      transformControls.attach(reference);
      transformControls.visible = false;
      transformControls.enabled = false;
      transformControls.addEventListener('dragging-changed', (event) => {
        controls.enabled = !event.value;
      });
      transformControls.addEventListener('change', () => {
        attractor.position.copy(reference.position);
        attractor.rotation.copy(reference.rotation);
      });
      scene.add(transformControls);
      
      attractor.helper = helper;
      attractor.controls = transformControls;
    });

    // Particles
    const positions = new Float32Array(params.particleCount * 3);
    const velocities = new Float32Array(params.particleCount * 3);
    const tempForce = new THREE.Vector3();
    const tempPosition = new THREE.Vector3();
    const tempDirection = new THREE.Vector3();
    const tempSpinForce = new THREE.Vector3();
    const mouse3D = new THREE.Vector3();
    const raycaster = new THREE.Raycaster();
    const mousePosition = new THREE.Vector2();
    const intersectPlane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0);
    
    const targetPosition = new THREE.Vector3(6, 8, 12);
    
    const handleMouseMove = (event: MouseEvent) => {
      if (isAnimatingRef.current) return;
      
      handlePointerMove(event.clientX, event.clientY);
    };
    
    const handleTouchMove = (event: TouchEvent) => {
      if (isAnimatingRef.current || !event.touches[0]) return;
      event.preventDefault();
      
      handlePointerMove(event.touches[0].clientX, event.touches[0].clientY);
    };
    
    const handleTouchStart = (event: TouchEvent) => {
      if (!event.touches[0]) return;
      touchStartRef.current = {
        x: event.touches[0].clientX,
        y: event.touches[0].clientY
      };
    };
    
    const handlePointerMove = (clientX: number, clientY: number) => {
      mousePosition.x = (clientX / window.innerWidth) * 2 - 1;
      mousePosition.y = -(clientY / window.innerHeight) * 2 + 1;

      const mouseX = (clientX / window.innerWidth - 0.5) * 2;
      const mouseY = (clientY / window.innerHeight - 0.5) * 2;
      
      const targetX = initialCameraPosition.current.x + mouseX * 4;
      const targetY = initialCameraPosition.current.y - mouseY * 4;
      const targetZ = initialCameraPosition.current.z + (mouseX * mouseY) * 2;
      
      currentCameraPosition.current.x += (targetX - currentCameraPosition.current.x) * lerpFactor;
      currentCameraPosition.current.y += (targetY - currentCameraPosition.current.y) * lerpFactor;
      currentCameraPosition.current.z += (targetZ - currentCameraPosition.current.z) * lerpFactor;
      
      camera.position.copy(currentCameraPosition.current);
      camera.lookAt(0, 0, 0);
      
      raycaster.setFromCamera(mousePosition, camera);
      raycaster.ray.intersectPlane(intersectPlane, mouse3D);
    };
    
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('touchmove', handleTouchMove, { passive: false });
    window.addEventListener('touchstart', handleTouchStart);
    
    for (let i = 0; i < positions.length; i += 3) {
      positions[i] = (Math.random() - 0.5) * 5;
      positions[i + 1] = (Math.random() - 0.5) * 5;
      positions[i + 2] = (Math.random() - 0.5) * 5;

      velocities[i] = 0;
      velocities[i + 1] = 0;
      velocities[i + 2] = 0;
    }

    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
    geometry.setAttribute('velocity', new THREE.BufferAttribute(velocities, 3));
    geometry.setAttribute('speed', new THREE.BufferAttribute(new Float32Array(params.particleCount), 1));
    geometryRef.current = geometry;

    // Custom shader material for color interpolation
    const material = new THREE.ShaderMaterial({
      uniforms: {
        colorA: { value: new THREE.Color(params.colorA) },
        colorB: { value: new THREE.Color(params.colorB) },
        colorTransitionThreshold: { value: params.colorTransitionThreshold },
        size: { value: params.scale },
        maxSpeed: { value: params.maxSpeed }
      },
      vertexShader: `
        attribute float speed;
        uniform float size;
        varying float vSpeed;
        
        void main() {
          vSpeed = speed;
          vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
          gl_PointSize = size * (600.0 / -mvPosition.z);
          gl_Position = projectionMatrix * mvPosition;
        }
      `,
      fragmentShader: `
        uniform vec3 colorA;
        uniform vec3 colorB;
        uniform float maxSpeed;
        uniform float colorTransitionThreshold;
        varying float vSpeed;
        
        void main() {
          float t = smoothstep(0.0, colorTransitionThreshold, vSpeed / maxSpeed);
          vec3 color = mix(colorA, colorB, t);
          
          vec2 center = gl_PointCoord * 2.0 - 1.0;
          float dist = length(center);
          float alpha = (1.0 - smoothstep(0.0, 0.9, dist)) * 1.2;
          
          gl_FragColor = vec4(color * 1.8, alpha);
        }
      `,
      blending: THREE.AdditiveBlending,
      transparent: true,
      depthWrite: false
    });
    materialRef.current = material;

    const points = new THREE.Points(geometry, material);
    scene.add(points);

    const G = 6.67e-11; // Gravitational constant

    // GUI Setup
    const gui = new GUI({ autoPlace: false });
    gui.close();
    const perfFolder = gui.addFolder('Performance');
    perfFolder.add(params, 'particleCount', 1000, 200000, 1000).name('Particle Count').onChange(() => {
      params.reset();
    });
    perfFolder.add(params, 'fps').listen().disable();
    
    const mouseFolder = gui.addFolder('Mouse Interaction');
    mouseFolder.add(params, 'mouseRepulsionStrength', 0, 20, 0.1).name('Repulsion Force');
    mouseFolder.add(params, 'mouseRepulsionRadius', 0, 8, 0.1).name('Repulsion Radius');
    mouseFolder.open();
    
    gui.add(params, 'attractorMassExponent', 1, 10, 1).name('Attractor Mass Exp');
    gui.add(params, 'particleGlobalMassExponent', 1, 10, 1).name('Particle Mass Exp');
    gui.add(params, 'maxSpeed', 0, 10, 0.01).onChange((value: number) => {
      material.uniforms.maxSpeed.value = value;
    });
    gui.add(params, 'colorTransitionThreshold', 0, 1, 0.01).onChange((value: number) => {
      material.uniforms.colorTransitionThreshold.value = value;
    });
    gui.add(params, 'velocityDamping', 0, 0.1, 0.001);
    gui.add(params, 'spinningStrength', 0, 10, 0.01);
    gui.add(params, 'scale', 0, 0.3, 0.001).onChange((value: number) => {
      material.uniforms.size.value = value;
    });
    gui.add(params, 'fadeOutDuration', 100, 1000, 50).name('Fade Duration (ms)');
    gui.add(params, 'boundHalfExtent', 0, 20, 0.01);
    gui.addColor(params, 'colorA').onChange((value: string) => {
      material.uniforms.colorA.value.set(value);
    });
    gui.addColor(params, 'colorB').onChange((value: string) => {
      material.uniforms.colorB.value.set(value);
    });
    gui.addColor(params, 'backgroundColor').onChange((value: string) => {
      renderer.setClearColor(value);
    });
    gui.add(params, 'controlsMode', ['translate', 'rotate', 'none']).onChange((value: string) => {
      attractors.forEach(attractor => {
        if (value === 'none') {
          attractor.controls.visible = false;
          attractor.controls.enabled = false;
        } else {
          attractor.controls.visible = true;
          attractor.controls.enabled = true;
          attractor.controls.mode = value;
        }
      });
    });
    gui.add(params, 'helperVisible').onChange((value: boolean) => {
      attractors.forEach(attractor => {
        attractor.helper.visible = value;
        attractor.controls.visible = value;
        attractor.controls.enabled = value;
      });
    });
    gui.add(params, 'reset');

    attractors.forEach((attractor, index) => {
      const attractorFolder = gui.addFolder(`Attractor ${index + 1}`);
      attractorFolder.add(attractor.position, 'x', -10, 10, 0.1).listen();
      attractorFolder.add(attractor.position, 'y', -10, 10, 0.1).listen();
      attractorFolder.add(attractor.position, 'z', -10, 10, 0.1).listen();
      attractorFolder.add(attractor.rotation, 'x', -Math.PI, Math.PI, 0.1).name('rotationX').listen();
      attractorFolder.add(attractor.rotation, 'y', -Math.PI, Math.PI, 0.1).name('rotationY').listen();
      attractorFolder.add(attractor.rotation, 'z', -Math.PI, Math.PI, 0.1).name('rotationZ').listen();
      attractorFolder.close();
    });

    // Animation
    let lastTime = performance.now();
    let frameCount = 0;
    let fpsUpdateTime = lastTime;
    const fixedDelta = 1 / 60;
    const transitionDuration = 2000; // 2 seconds
    
    const fadeToBlack = () => {
      if (fadeOverlayRef.current) {
        fadeOverlayRef.current.style.transition = 'opacity 1000ms ease-in';
        fadeOverlayRef.current.style.opacity = '1';
        setTimeout(() => {
          window.dispatchEvent(new CustomEvent('zoomToCenter'));
          navigate('/');
        }, 1000);
      }
    };
    
    startCameraAnimationRef.current = () => {
      isAnimatingRef.current = true;
      const startPosition = camera.position.clone();
      const targetPosition = new THREE.Vector3(0, 0, 0);
      const duration = 3000;
      const fadeStartTime = 1000; // Start fade after 1 second
      const startTime = performance.now();
      
      function animate() {
        const currentTime = performance.now();
        const elapsed = currentTime - startTime;
        const progress = Math.min(elapsed / duration, 1);

        const t = progress < 0.5
          ? 4 * progress * progress * progress
          : 1 - Math.pow(-2 * progress + 2, 3) / 2;

        camera.position.lerpVectors(startPosition, targetPosition, t);
        camera.lookAt(0, 0, 0);

        // Start fade after fadeStartTime milliseconds
        if (elapsed >= fadeStartTime && fadeOverlayRef.current && fadeOverlayRef.current.style.opacity === '0') {
          fadeOverlayRef.current.style.transition = 'opacity 1000ms ease-in';
          fadeOverlayRef.current.style.opacity = '1';
        }

        if (progress < 1) {
          requestAnimationFrame(animate);
        } else {
          setTimeout(() => {
            navigate('/project');
          }, 500); // Add a small delay after animation completes
        }
      }

      animate();
    };
    
    const animate = () => {
      const currentTime = performance.now();
      frameCount++;

      if (currentTime - fpsUpdateTime > 1000) {
        params.fps = Math.round(frameCount * 1000 / (currentTime - fpsUpdateTime));
        frameCount = 0;
        fpsUpdateTime = currentTime;
      }

      const positions = geometry.attributes.position.array as Float32Array;
      const velocities = geometry.attributes.velocity.array as Float32Array;

      for (let i = 0; i < positions.length; i += 3) {
        tempPosition.set(positions[i], positions[i + 1], positions[i + 2]);
        tempForce.set(0, 0, 0);

        for (const attractor of attractors) {
          const attractorMass = Math.pow(10, params.attractorMassExponent);
          const particleMass = Math.pow(10, params.particleGlobalMassExponent);
          
          tempDirection.copy(attractor.position).sub(tempPosition);
          const distance = tempDirection.length();
          tempDirection.normalize();

          const gravityStrength = (G * attractorMass * particleMass) / (distance * distance);
          tempForce.add(tempDirection.multiplyScalar(gravityStrength));
          
          tempSpinForce.set(0, 1, 0)
            .multiplyScalar(gravityStrength * params.spinningStrength);
          tempForce.add(tempSpinForce.cross(tempDirection));
        }

        tempDirection.set(
          tempPosition.x - mouse3D.x,
          0,
          tempPosition.z - mouse3D.z
        );
        const mouseDistance = tempDirection.length();
        
        const repulsionRadius = params.mouseRepulsionRadius;
        if (mouseDistance < repulsionRadius) {
          tempDirection.normalize();
          tempDirection.y = 0.5;
          tempDirection.normalize();
          const t = 1 - (mouseDistance / repulsionRadius);
          const falloff = t * t * (3 - 2 * t);
          const repulsionStrength = falloff * params.mouseRepulsionStrength;
          tempForce.add(tempDirection.multiplyScalar(repulsionStrength));
        }

        velocities[i] += tempForce.x * fixedDelta;
        velocities[i + 1] += tempForce.y * fixedDelta;
        velocities[i + 2] += tempForce.z * fixedDelta;

        const speed = tempForce.set(velocities[i], velocities[i + 1], velocities[i + 2]).length();

        if (speed > params.maxSpeed) {
          const scale = params.maxSpeed / speed;
          velocities[i] *= scale;
          velocities[i + 1] *= scale;
          velocities[i + 2] *= scale;
        }

        geometry.attributes.speed.array[i/3] = speed;

        velocities[i] *= (1 - params.velocityDamping);
        velocities[i + 1] *= (1 - params.velocityDamping);
        velocities[i + 2] *= (1 - params.velocityDamping);

        positions[i] += velocities[i];
        positions[i + 1] += velocities[i + 1];
        positions[i + 2] += velocities[i + 2];

        const wrap = (value: number) => {
          const halfExtent = params.boundHalfExtent / 2;
          return ((value + halfExtent) % params.boundHalfExtent) - halfExtent;
        };

        positions[i] = wrap(positions[i]);
        positions[i + 1] = wrap(positions[i + 1]);
        positions[i + 2] = wrap(positions[i + 2]);
      }
      
      geometry.attributes.position.needsUpdate = true;
      geometry.attributes.speed.needsUpdate = true;
      
      controls.update();
      renderer.render(scene, camera);
      requestAnimationFrame(animate);
    };

    const handleResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    animate();

    // Cleanup function
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchstart', handleTouchStart);
      
      // Properly dispose of Three.js resources
      if (rendererRef.current) {
        rendererRef.current.dispose();
        containerRef.current?.removeChild(rendererRef.current.domElement);
      }
      
      if (geometryRef.current) {
        geometryRef.current.dispose();
      }
      
      if (materialRef.current) {
        materialRef.current.dispose();
      }
      
      if (sceneRef.current) {
        sceneRef.current.traverse((object) => {
          if (object instanceof THREE.Mesh) {
            object.geometry.dispose();
            if (object.material instanceof THREE.Material) {
              object.material.dispose();
            } else if (Array.isArray(object.material)) {
              object.material.forEach(material => material.dispose());
            }
          }
        });
      }
      
      if (gui) {
        gui.destroy();
        attractors.forEach(attractor => {
          attractor.controls.dispose();
          attractor.helper.traverse(child => {
            if (child instanceof THREE.Mesh) {
              child.geometry.dispose();
              child.material.dispose();
            }
          });
        });
        controls.dispose();
      }
      
      // Clear any ongoing animations
      if (requestAnimationFrameId.current) {
        cancelAnimationFrame(requestAnimationFrameId.current);
      }
    };
  }, []);

  const handleZoom = () => {
    isAnimatingRef.current = true;
    if (controlsRef.current) {
      controlsRef.current.enabled = false;
      controlsRef.current.enableZoom = false;
      controlsRef.current.enableRotate = false;
      controlsRef.current.enablePan = false;
      controlsRef.current.enableDamping = false;
    }
    startCameraAnimationRef.current?.();
  };

  return (
    <div className="w-full h-screen relative" ref={containerRef}>
      <Header />
      {!isMobile && <div className="fixed top-8 right-8 z-50">
        <button
          onClick={() => setShowPasswordModal(true)}
          className="p-3 rounded-full opacity-0 w-12 h-12"
        >
          <Lock className="w-4 h-4" />
        </button>
      </div>}
      {showPasswordModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <form
            onSubmit={handlePasswordSubmit}
            className="bg-[#1A1A1B] p-6 rounded-lg shadow-xl"
          >
            <h2 className="text-xl mb-4">Enter Admin Password</h2>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full bg-[#2A2A2B] text-white px-4 py-2 rounded mb-4"
              placeholder="Password"
              autoFocus
            />
            {error && <p className="text-red-400 mb-4">{error}</p>}
            <div className="flex justify-end gap-2">
              <button
                type="button"
                onClick={() => setShowPasswordModal(false)}
                className="px-4 py-2 text-gray-400 hover:text-gray-200"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 hover:bg-blue-600 rounded"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
      <div 
        ref={fadeOverlayRef}
        className="fixed inset-0 bg-[#111111] opacity-0 pointer-events-none" 
        style={{ zIndex: 100000, transition: 'opacity 2500ms ease-out' }}
      />
      {!isFading && <EnterButton onClick={handleZoom} />}
    </div>
  );
};

export default ParticleScene;