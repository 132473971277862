import React, { useRef, useEffect } from 'react';

interface GlowingTextProps {
  text: string;
  className?: string;
}

const GlowingText: React.FC<GlowingTextProps> = ({ text, className = '' }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const words = text.split(' ');
  
  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    
    const resetSpans = () => {
      const spans = container.getElementsByTagName('span');
      Array.from(spans).forEach((span) => {
        span.style.color = '';
        span.style.textShadow = '';
      });
    };
    
    const spans = container.getElementsByTagName('span');
    const handleMouseMove = (e: MouseEvent) => {
      const rect = container.getBoundingClientRect();
      const mouseX = e.clientX - rect.left;
      const mouseY = e.clientY - rect.top;
      
      const maxDistance = 50; // Even smaller radius for more intense effect
      
      Array.from(spans).forEach((span) => {
        const spanRect = span.getBoundingClientRect();
        const spanX = spanRect.left - rect.left + spanRect.width / 2;
        const spanY = spanRect.top - rect.top + spanRect.height / 2;
        
        const distance = Math.sqrt(
          Math.pow(mouseX - spanX, 2) + 
          Math.pow(mouseY - spanY, 2)
        );
        
        const brightness = Math.max(0, 1 - distance / maxDistance);
        
        // Only apply glow effect to nearby text
        if (brightness > 0) {
          span.style.color = `rgba(255, 255, 255, ${0.98 + brightness * 0.02})`;
        } else {
          span.style.color = '';
        }
        
        span.style.textShadow = `0 0 ${brightness * 30}px rgba(255, 255, 255, ${brightness * 1.2}),
                                0 0 ${brightness * 15}px rgba(105, 197, 236, ${brightness * 1.3}),
                                0 0 ${brightness * 5}px rgba(105, 197, 236, ${brightness * 1.4})`;
      });
    };
    
    const handleMouseLeave = () => {
      resetSpans();
    };
    
    container.addEventListener('mousemove', handleMouseMove);
    container.addEventListener('mouseleave', handleMouseLeave);
    
    return () => {
      container.removeEventListener('mousemove', handleMouseMove);
      container.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  return (
    <div ref={containerRef} className={`relative ${className}`}>
      {words.map((word, i) => (
        <React.Fragment key={i}>
          <span className="transition-all duration-300 ease-out">
            {word}
          </span>
          {i < words.length - 1 && ' '}
        </React.Fragment>
      ))}
    </div>
  );
};

export default GlowingText;