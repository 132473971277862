import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown } from 'lucide-react';

const ScrollIndicator = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const rootElement = document.getElementById('root');
    if (!rootElement) return;
    
    const handleScroll = () => {
      if (rootElement.scrollTop > 0) {
        setIsVisible(false);
      }
    };

    rootElement.addEventListener('scroll', handleScroll);
    return () => rootElement.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 10 }}
          transition={{ duration: 0.3, ease: "easeOut" }}
          className="fixed bottom-12 left-1/2 -translate-x-1/2 z-50"
        >
          <ChevronDown className="w-10 h-10 text-white/90 animate-bounce" strokeWidth={3} />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ScrollIndicator;