import React from 'react';
import { useProjects } from '../hooks/useProjects';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { loading: projectsLoading } = useProjects();

  return (
    <div className="min-h-full bg-[#111111] text-gray-100">
      <main className={`min-h-full transition-opacity duration-100 ${projectsLoading ? 'opacity-0' : 'opacity-100'}`}>
        {children}
      </main>
    </div>
  );
};

export default Layout;