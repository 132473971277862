export interface Project {
  id: string;
  title: string;
  description: string;
  image: string;
  year: string;
  category: string;
  icon_key?: string;
  media?: {
    type: 'image' | 'video';
    url: string;
    caption?: string;
  }[];
  overview?: string;
  key_work?: string;
  links?: Record<string, string>;
}

// Note: This array is now only used as a fallback if database fetch fails
export const defaultProjects: Project[] = [
  {
    id: 'biotech-simulator',
    title: 'Biotech Simulator',
    description: 'Advanced simulation platform for molecular interactions and drug discovery',
    image: 'https://images.unsplash.com/photo-1532187863486-abf9dbad1b69?auto=format&fit=crop&q=80',
    year: '2024',
    category: 'Biotechnology',
    overview: 'A cutting-edge simulation platform that leverages machine learning to predict molecular interactions and accelerate drug discovery processes. The system utilizes advanced visualization techniques to represent complex molecular structures and their behaviors.',
    media: [
      {
        type: 'image',
        url: 'https://images.unsplash.com/photo-1582719471384-894fbb16e074?auto=format&fit=crop&q=80',
        caption: 'Molecular visualization interface'
      },
      {
        type: 'image',
        url: 'https://images.unsplash.com/photo-1576086213369-97a306d36557?auto=format&fit=crop&q=80',
        caption: 'Data analysis dashboard'
      }
    ],
    links: {
      'Live Demo': 'https://example.com/demo',
      'Documentation': 'https://example.com/docs',
      'Source Code': 'https://github.com/example/project'
    },
  },
  {
    id: 'neural-interface',
    title: 'Neural Interface',
    description: 'Experimental brain-computer interface visualization system using real-time neural data processing',
    image: 'https://images.unsplash.com/photo-1620712943543-bcc4688e7485?auto=format&fit=crop&q=80',
    year: '2024',
    category: 'AI Research',
    overview: 'A revolutionary brain-computer interface that processes neural signals in real-time, creating intuitive visualizations of brain activity patterns. This project pushes the boundaries of human-computer interaction.'
  },
  {
    id: 'quantum-visualizer',
    title: 'Quantum Visualizer',
    description: 'Interactive 3D visualization of quantum computing processes and algorithms',
    image: 'https://images.unsplash.com/photo-1635070041078-e363dbe005cb?auto=format&fit=crop&q=80',
    year: '2023',
    category: 'Quantum Computing',
    overview: 'An innovative platform for visualizing quantum computing concepts in 3D space. This tool helps researchers and students understand complex quantum algorithms through interactive visualizations.'
  },
  {
    id: 'holographic-interface',
    title: 'Holographic Interface',
    description: 'Next-generation AR interface for controlling smart home systems through gesture recognition',
    image: 'https://images.unsplash.com/photo-1506318137071-a8e063b4bec0?auto=format&fit=crop&q=80',
    year: '2023',
    category: 'AR/VR',
    overview: 'A groundbreaking augmented reality interface that transforms how users interact with their smart home environment through natural gestures and holographic displays.'
  },
  {
    id: 'data-nexus',
    title: 'Data Nexus',
    description: 'Real-time data visualization platform with AI-driven insights',
    image: 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80',
    year: '2023',
    category: 'Data Science',
    overview: 'An advanced data visualization platform that combines real-time analytics with AI-powered insights, making complex data patterns accessible and actionable.'
  },
  {
    id: 'neural-networks-lab',
    title: 'Neural Networks Lab',
    description: 'Interactive platform for experimenting with neural network architectures',
    image: 'https://images.unsplash.com/photo-1558494949-ef010cbdcc31?auto=format&fit=crop&q=80',
    year: '2022',
    category: 'Machine Learning',
    overview: 'A comprehensive laboratory environment for designing, training, and analyzing neural networks. Features include real-time visualization of network behavior and interactive architecture modification.'
  },
  {
    id: 'ai-art-generator',
    title: 'AI Art Generator',
    description: 'Creative AI system for generating unique digital artwork',
    image: 'https://images.unsplash.com/photo-1547826039-bfc35e0f1ea8?auto=format&fit=crop&q=80',
    year: '2022',
    category: 'AI/Art',
    overview: 'An innovative AI-powered art generation system that creates unique digital artwork based on text descriptions and style preferences.'
  },
  {
    id: 'blockchain-viz',
    title: 'Blockchain Visualizer',
    description: 'Real-time blockchain transaction visualization platform',
    image: 'https://images.unsplash.com/photo-1644143379190-08a5f055de1d?auto=format&fit=crop&q=80',
    year: '2022',
    category: 'Blockchain',
    overview: 'A dynamic visualization platform that renders blockchain transactions and network activity in real-time, making complex blockchain data accessible and understandable.'
  },
  {
    id: 'eco-simulator',
    title: 'Ecosystem Simulator',
    description: 'Environmental impact simulation and prediction system',
    image: 'https://images.unsplash.com/photo-1619266465172-02a857c3556d?auto=format&fit=crop&q=80',
    year: '2021',
    category: 'Environmental',
    overview: 'An advanced ecosystem simulation platform that models environmental changes and predicts their impact on various ecological systems.'
  }
];