import React from 'react';
import { Brain, Cpu, Database, Network, Palette, Bot, Globe, Rocket, Zap, Hand, MapPin, ChevronDown } from 'lucide-react';
import { useAdmin } from '../contexts/AdminContext';

export const categoryIcons = {
  'BrainIcon': Brain,
  'CpuIcon': Cpu,
  'DatabaseIcon': Database,
  'NetworkIcon': Network,
  'PaletteIcon': Palette,
  'BotIcon': Bot,
  'GlobeIcon': Globe,
  'RocketIcon': Rocket,
  'ZapIcon': Zap,
  'HandIcon': Hand,
  'MapPinIcon': MapPin,
} as const;

interface IconSelectProps {
  onIconChange: (iconKey: string) => void;
  selectedIcon?: string;
}

const IconSelect: React.FC<IconSelectProps> = ({ selectedIcon = 'CpuIcon', onIconChange }) => {
  const Icon = categoryIcons[selectedIcon as keyof typeof categoryIcons] || categoryIcons['CpuIcon'];
  const [isOpen, setIsOpen] = React.useState(false);
  const { isAdmin } = useAdmin();
  
  const handleIconClick = (e: React.MouseEvent) => {
    e.preventDefault(); // Prevent link navigation
    setIsOpen(!isOpen);
  };

  const handleIconSelect = (e: React.MouseEvent, category: string) => {
    e.preventDefault(); // Prevent link navigation
    onIconChange(category);
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block">
      {isAdmin ? (
        <button
          onClick={handleIconClick}
          className="flex items-center gap-2 p-1 rounded hover:bg-[#1A1A1B] transition-colors relative z-10"
          title="Click to change category"
        >
          <Icon className="w-4 h-4" />
          <ChevronDown className="w-4 h-4" />
        </button>
      ) : (
        <Icon className="w-4 h-4" />
      )}
      
      {isOpen && (
        <>
          <div 
            className="fixed inset-0 z-10" 
            onClick={(e) => {
              e.preventDefault();
              setIsOpen(false);
            }}
          />
          <div className="absolute left-0 mt-1 w-48 rounded-lg bg-[#1A1A1B] shadow-lg ring-1 ring-black ring-opacity-5 z-20">
            <div className="py-1">
              {Object.entries(categoryIcons).map(([category, CategoryIcon]) => (
                <button
                  key={category}
                  onClick={(e) => handleIconSelect(e, category)}
                  className={`flex items-center gap-2 px-4 py-2 text-sm w-full hover:bg-[#2A2A2B] transition-colors ${
                    category === selectedIcon ? 'text-blue-400' : 'text-gray-300'
                  }`}
                >
                  <CategoryIcon className="w-4 h-4" />
                </button>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default IconSelect;